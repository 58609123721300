.container {
  margin: 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.compare {
  display: flex;
  flex-direction: row;
}

.canvas {
  position: relative;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-inline: 5pt;
}

.top-left {
  position: absolute;
  top: 4pt;
  left: 4pt;
}

.textbox {
  font-size: 30pt;
  border: 1px solid black;
  line-height: 1;
  letter-spacing: 0;
  white-space: nowrap;
  text-align: left;
  margin: 0;
}
